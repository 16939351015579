import { Button } from '@findigs/ui-kit/src/components/button';
import { useCallback } from 'react';
import styled from 'styled-components';
import type { UseRemoveProfileModalState } from '../hooks/use-remove-profile-modal';
import ConfirmModalLayout from '../layouts/confirm-modal-layout';
import type { ModalProps } from '../state/modal';
import PetsIcon from '../images/modals/pets-warning-icon.inline.svg';

export interface RemoveProfileModalProps extends ModalProps {
  options: UseRemoveProfileModalState['options'];
}

const StyledConfirmModalLayout = styled(ConfirmModalLayout)`
  /** 768px - tablet **/
  @media (min-width: 48rem) {
    max-width: 27.25rem;
  }
`;

const RemoveProfileModal = ({
  options: { onConfirm },
  hide,
}: RemoveProfileModalProps) => {
  const _onConfirm = useCallback(() => {
    hide();
    if (onConfirm) {
      onConfirm();
    }
  }, [hide, onConfirm]);

  const Confirm = (
    <Button type="button" variant="FilledStandard" onClick={_onConfirm}>
      Go to dashboard
    </Button>
  );
  const Cancel = (
    <Button type="button" variant="Tonal" onClick={hide}>
      Cancel
    </Button>
  );

  return (
    <StyledConfirmModalLayout
      onDismiss={hide}
      icon={<PetsIcon aria-hidden={true} />}
      actions={[Confirm, Cancel]}
    >
      <h2 className="text-t-sm mb-8">Remove an animal profile?</h2>
      <p className="text-b-md-regular text-muted">
        You&apos;ll be redirected to your Findigs application dashboard where
        you can remove pet or animal profiles.
      </p>
    </StyledConfirmModalLayout>
  );
};

export default RemoveProfileModal;
